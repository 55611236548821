<template>
  <div>
    <a-form layout="inline" :form="form1" @submit="handleSearch">
      <a-form-item>
        <a-input
          v-decorator="['key']"
          placeholder="请输入账号或昵称"
          autocomplete="off"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-select v-decorator="['status']" class="sm-select" placeholder="状态">
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="complete"> 已完成 </a-select-option>
          <a-select-option value="unfinished"> 未完成 </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> 搜索 </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="button" @click="handleClear"> 刷新 </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: true }"
    >
      <span slot="status" slot-scope="text">
        <span v-if="text === 'complete'">完成</span>
        <span v-else-if="text === 'unfinished'">未完成</span>
      </span>
      <span slot="pay_way" slot-scope="text">
        <span v-if="text === 'alipay'">支付宝</span>
        <span v-else-if="text === 'wechat_pay'">微信</span>
      </span>
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
  </div>
</template>

<script>
import { VipSubscribeOrderModel } from "@/models/vipSubscribeOrders";
import Pagination from "../../components/Pagination";
import formatTime from "../../utils/formatTime";
import userNameDisplay from "../../utils/userNameDisplay";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

const vipSubscribeOrderModel = new VipSubscribeOrderModel();
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");

const columns = [
  {
    title: "订单号",
    dataIndex: "order_no",
    key: "order_no",
  },
  {
    title: "订单状态",
    dataIndex: "status",
    key: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "订单完成时间",
    dataIndex: "updated_at",
    key: "updated_at",
  },
  {
    title: "订阅后VIP会员到期时间",
    dataIndex: "end_time",
    key: "end_time",
  },
  {
    title: "订单金额(元)",
    dataIndex: "total_fee",
    key: "total_fee",
  },
  {
    title: "支付方式",
    dataIndex: "pay_method",
    key: "pay_method",
    scopedSlots: { customRender: "pay_way" },
  },
  {
    title: "套餐名称",
    dataIndex: "vip_set_name",
    key: "vip_set_name",
  },
  {
    title: "套餐服务周期",
    dataIndex: "subscribe_period",
    key: "subscribe_period",
  },
  {
    title: "套餐价格",
    dataIndex: "total_fee",
    key: "total_fee",
  },
  {
    title: "用户账号",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "用户昵称",
    dataIndex: "nick_name",
    key: "nick_name",
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      loading: false,
      total: 0,
      locale,
      current: 1,
    };
  },

  created() {
    this.queryVipSubscribeOrders();
  },

  methods: {
    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          let status = values.status;
          this.queryVipSubscribeOrders(key, status, this.current);
        }
      });
    },

    handleClear() {
      this.form1.resetFields();
      this.queryVipSubscribeOrders();
    },

    handleChange(page) {
      this.current = page;
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          let status = values.status;
          this.queryVipSubscribeOrders(key, status, this.current);
        }
      });
    },

    queryVipSubscribeOrders(key, status, page) {
      this.loading = true;
      vipSubscribeOrderModel.index(key, status, page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            item.key = index;
            item.user_name = userNameDisplay(item);
            if (item.status == "complete") {
              item.updated_at = formatTime(item.updated_at);
              item.end_time = formatTime(item.end_time);
            } else {
              item.updated_at = "";
            }
            return item;
          });
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
</style>
