import {HTTP} from '../utils/axios'

class VipSubscribeOrderModel extends HTTP {
  /*首页渲染*/
  index(key = '', status = '', page = 1) {
      let data = {}
      data.key = key
      data.status = status
      data.page = page
      return this.request({
          url: 'board/vip_subscribe_orders',
          method: "GET",
          data: data
      })
  }

  totalAmount() {
    return this.request({
        url: 'board/vip_subscribe_orders/total_amount',
        method: "GET"
    })
  }
}

export {VipSubscribeOrderModel}